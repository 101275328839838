import React from 'react'
import { useDispatch } from 'react-redux'
import { Box, Drawer, List, ListItem, ListItemText, ListItemAvatar } from '@material-ui/core'
import { CompanySelector, MenuItem } from 'components'
import {
  LocalOfferOutlined as OfferIcon,
  InfoOutlined as InfoIcon,
  Payment as PaymentIcon,
  Add as AddIcon,
  AssessmentOutlined as DashboardIcon,
  ReceiptOutlined as TransactionIcon,
  PeopleOutlined as UsersIcon,
} from '@material-ui/icons'
import { showBusinessForm } from 'modules/UI/actions'
import LogoSrc from 'components/Logo/Logo.svg'
import { useAdminUser } from 'utils/roles'

const MobileMenu = ({
  isOpen,
  onClose,
}) => {
  const isAdmin = useAdminUser()
  const dispatch = useDispatch()
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      <Box p={2} pb={0}>
        <img src={LogoSrc} alt="" height={50} />
      </Box>
      <CompanySelector />
      <List style={{ minWidth: 300 }}>
        <MenuItem icon={<DashboardIcon />} text="Dashboard" link="dashboard" />
        <MenuItem icon={<OfferIcon />} text="Offers" link="offers" />
        <MenuItem icon={<TransactionIcon />} text="Transactions" link="transactions" />
        <MenuItem icon={<PaymentIcon />} text="Payments" link="payments" />
        <MenuItem icon={<InfoIcon />} text="Business profile" link="profile" />
      </List>
      <Box py={3}>
        <List>
          <ListItem button onClick={() => {
            onClose()
            dispatch(showBusinessForm())
          }} style={{ background: '#eee' }}>
            <ListItemAvatar>
              <AddIcon />
            </ListItemAvatar>
            <ListItemText>
              Create new business
            </ListItemText>
          </ListItem>
        </List>
      </Box>
      {
        isAdmin &&
        <Box>
          <List>
            <MenuItem icon={<UsersIcon />} text="User List" link="user-list" />
            <MenuItem icon={<OfferIcon />} text="Coupons" link="coupons" />
          </List>
        </Box>
      }
    </Drawer>
  )
}

export default MobileMenu
