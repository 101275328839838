import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, Dialog, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import LogoSrc from 'components/Logo/Logo.svg'
import api from 'utils/api'

const m = {
  fail: 'fail',
  success: 'success',
}

const ResetPassword = () => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  const handleSubmit = (e) => {
    e.preventDefault()
    api
      .post('/auth/forgot-password', { email })
      .then(() => {
        setMessage(m.success)
        setEmail('')
      })
      .catch(() => {
        setMessage(m.fail)
      })
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Dialog fullWidth maxWidth="xs" open onClose={() => {}}>
        <Box p={matches ? 2 : 4} pt={matches ? 1 : 2}>
          <Box p={2} pb={4} display="flex" justifyContent="center">
            <Link to="/">
              <img src={LogoSrc} height={40} alt="HiddenUnderground logo" />
            </Link>
          </Box>
          <Typography variant="h6" style={{ fontWeight: 700 }}>Reset password</Typography>
          <form onSubmit={handleSubmit}>
            <Box my={2}>
              <TextField
                autoFocus
                defaultValue=""
                fullWidth
                label="Email"
                name="email"
                onChange={e => setEmail(e.target.value)}
                variant="outlined"
                value={email}
                type="email"
                required
              />
            </Box>
            <Button type="submit" variant="contained" color="primary" disableElevation>
              Submit
            </Button>
            <Box my={1}>
              {
                message === m.success &&
                <Typography color="primary">
                  You will receive an email with instructions
                </Typography>
              }
              {
                message === m.fail &&
                <Typography color="error">
                  We cannot reset your password
                </Typography>
              }
            </Box>
          </form>
          <Box mt={2}>
            <Link to="/" style={{ color: '#555', fontSize: 13 }}>
              Return to homepage
            </Link>
          </Box>
        </Box>
      </Dialog>
    </Box>
  )
}

export default ResetPassword
