import React from 'react'
import moment from 'moment'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import { useSelector } from 'react-redux'
import { Info } from '@material-ui/icons'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { Controller, useForm } from 'react-hook-form'
import { period, periodLabels } from 'utils/constants'
import { dateTimeFormat, parsableDateTimeFormat } from 'utils/time'

const schema = yup.object().shape({
  maxAmount: yup.number().min(1, 'Minimum of 1$ is required').required('Required'),
  maxClick: yup.number().min(1, 'Minimum of 1 click is required').required('Required'),
  startDate: yup.string().required('Start date is required'),
  endDate: yup.string().required('End date is required'),
})

const OfferBudget = ({ back, onClose, onComplete, values }) => {
  const { businessBudget } = useSelector(state => state.business)
  const { register, control, handleSubmit, errors, watch } = useForm({
    defaultValues: values.budget || {
      amountPeriod: period.daily,
      clickPeriod: period.daily,
      maxAmount: 5,
      maxClick: 5,
    },
    resolver: yupResolver(schema),
  })

  const startDate = watch('startDate')

  const handleFormSubmit = (data) => {
    onComplete(data)
  }

  return (
    <>
      <Box mb={2}>
        <Typography variant="h6" component="h2">
          Set a total budget for your offers
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          You can allocate and adjust portions of this amount to each offer you
          create
          <Tooltip title={
            <>
              <Typography variant="h6">How your budget works</Typography>
              <Typography variant="body2">What you pay for</Typography>
              <Typography variant="caption" paragraph>Pay only when people click on your offer.</Typography>
              <Typography variant="body2">Set your max budget</Typography>
              <Typography variant="caption" paragraph>Costs can vary per click, but you won’t spend more than your max budget for the selected amount of time.</Typography>
              <Typography variant="body2">Set your max clicks</Typography>
              <Typography variant="caption" paragraph>Also manage your offer by the max number of clicks for the selected duration. Your offer will pause when the max is reached.</Typography>
            </>
          }>
            <IconButton size="small">
              <Info />
            </IconButton>
          </Tooltip>
        </Typography>
        {
          businessBudget &&
          <Typography variant="body2">
            Your business budget: ${businessBudget.maxAmount} {periodLabels[businessBudget.amountPeriod].toLowerCase()}, {businessBudget.maxClick} clicks {periodLabels[businessBudget.clickPeriod].toLowerCase()}
          </Typography>
        }
      </Box>
      <Grid container spacing={2} sm={12}>
        <Grid item container spacing={2} xs={12} md={8}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <TextField
                label="Max budget"
                defaultValue=""
                name="maxAmount"
                inputRef={register}
                variant="outlined"
                margin="dense"
                type="number"
                min={0}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                inputProps={{
                  min: 1
                }}
              />
              {errors.maxAmount && (
                <FormHelperText error>{errors.maxAmount.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth style={{ margin: '8px 0' }}>
              <InputLabel id="budget-period">Period</InputLabel>
              <Controller
                name="amountPeriod"
                control={control}
                as={
                  <Select
                    fullWidth
                    labelId="budget-period"
                    margin="dense"
                    variant="outlined"
                    label="Period"
                  >
                      {
                        Object.keys(period).map(key => (
                          <MenuItem value={period[key]}>
                            {key}
                          </MenuItem>
                        ))
                      }
                  </Select>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <TextField
                label="Max clicks"
                defaultValue=""
                name="maxClick"
                inputRef={register}
                variant="outlined"
                margin="dense"
                type="number"
                min={0}
                inputProps={{
                  min: 1
                }}
              />
              {errors.maxClick && (
                <FormHelperText error>{errors.maxClick.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth style={{ margin: '8px 0' }}>
              <InputLabel id="click-period">Period</InputLabel>
              <Controller
                name="clickPeriod"
                control={control}
                as={
                  <Select
                    fullWidth
                    labelId="click-period"
                    margin="dense"
                    variant="outlined"
                    label="Period"
                  >
                      {
                        Object.keys(period).map(key => (
                          <MenuItem value={period[key]}>
                            {key}
                          </MenuItem>
                        ))
                      }
                  </Select>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography variant="h6" component="h2">
                Set a time limit for your offers
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <Controller
                name="startDate"
                control={control}
                render={({ onChange, value }) => (
                  <DateTimePicker
                    ampm={false}
                    autoOk
                    variant="inline"
                    format={dateTimeFormat}
                    initialFocusedDate={moment().startOf('hour')}
                    inputVariant="outlined"
                    label="Start date"
                    margin="dense"
                    minutesStep={60}
                    placeholder={dateTimeFormat}
                    views={['date', 'hours']}
                    value={value ? moment(value, parsableDateTimeFormat) : null}
                    onChange={val => {
                      onChange(val ? val.format(parsableDateTimeFormat) : undefined)
                    }
                    }
                    KeyboardButtonProps={{
                      'aria-label': 'Change start date',
                    }}
                  />
                )}
              />
              {
                errors.startDate &&
                <FormHelperText error>
                  {errors.startDate.message}
                </FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined" error={!!errors.endDate}>
              <Controller
                name="endDate"
                control={control}
                render={({ onChange, value }) => (
                  <DateTimePicker
                    ampm={false}
                    autoOk
                    initialFocusedDate={moment().startOf('hour')}
                    minDate={moment(startDate, parsableDateTimeFormat).add(1, 'day')}
                    minDateMessage="End time should be after the start time"
                    variant="inline"
                    format={dateTimeFormat}
                    inputVariant="outlined"
                    label="End date"
                    margin="dense"
                    minutesStep={60}
                    views={['date', 'hours']}
                    value={value ? moment(value, parsableDateTimeFormat) : null}
                    onChange={val => {
                      onChange(val ? val.format(parsableDateTimeFormat) : undefined)
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'Change end date',
                    }}
                  />
                )}
              />
              {
                errors.endDate &&
                <FormHelperText>
                  {errors.endDate.message}
                </FormHelperText>
              }
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={6} mb={1} display="flex" justifyContent="space-between">
        <Box>
          <Button onClick={onClose}>
            Cancel
          </Button>
        </Box>
        <Box>
          <Button
            color="primary"
            onClick={back}
          >
            Back
          </Button>
          <Button
            color="primary"
            onClick={handleSubmit(handleFormSubmit)}
            variant="contained"
            type="submit"
          >
            Next
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default OfferBudget
