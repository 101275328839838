import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'
import Stepper from '../Stepper'
import OfferAudience from '../OfferAudience'
import OfferBudget from '../OfferBudget'
import OfferDescription from '../OfferDescription'
import OfferCode from '../OfferCode'
import OfferDetails from '../OfferDetails'
import OfferReview from '../OfferReview'
import api from 'utils/api'
import { mapOfferToForm } from './utils'
import { dateTimeFormat } from 'utils/time'

const useStyles = makeStyles(() => ({
  scrollPaper: {
    alignItems: 'flex-start',
  },
}))

const OfferWizard = ({ offer, onClose }) => {
  const classes = useStyles()
  const businessCategories = useSelector(state => state.business.categories)
  const businessKeywords = useSelector(state => state.business.keywords)
  const businessId = useSelector(state => state.business.businessId)
  const { enqueueSnackbar } = useSnackbar()
  const [activeStep, setActiveStep] = useState(offer ? 5 : 0)
  const [values, setValues] = useState(
    offer ?
    mapOfferToForm(offer.offer)
    :
    {
      description: {
        category: businessCategories,
        keywords: businessKeywords,
      },
    }
  )

  const validateStep = (index) => {
    setActiveStep(index + 1)
  }

  const handleSubmit = () => {
    const payload = {
      ...values.description,
      ...values.details,
      ...values.code,
      offerLocation: values.audience,
      budget: values.budget,
      businessId,
      startDate: moment(values.budget.startDate).toISOString(),
      endDate: moment(values.budget.endDate).toISOString(),
    }

    if (!offer) {
      api.post('/offer', payload)
        .then(response => {
          if ('EXPIRED' === response.status) {
            enqueueSnackbar('Offer set to Expired state.',
                { autoHideDuration: 10_000, variant: 'success' })
          } else if ('SCHEDULED' === response.status) {
            enqueueSnackbar('Offer scheduled on ' +
                moment(payload.startDate).startOf('hour').format(dateTimeFormat),
                { variant: 'success' })
          } else if ('PAUSED' === response.status) {
            enqueueSnackbar('Offer set to Paused state. Reason: ' + response.statusReason,
                { autoHideDuration: 10_000, variant: 'success' })
          } else {
            enqueueSnackbar('Offer Launched.', { variant: 'success' })
          }

          onClose()
        })
        .catch(() => {
          enqueueSnackbar(`Can't add new offer`, { variant: 'error' })
        })
      return
    }

    api.patch(`/offer/${offer.offer.offerId}`, payload)
      .then(response => {
        if ('EXPIRED' === response.status) {
          enqueueSnackbar('Offer set to Expired state.',
              { autoHideDuration: 10_000, variant: 'success' })
        } else if ('SCHEDULED' === response.status) {
          enqueueSnackbar('Offer scheduled on ' +
              moment(payload.startDate).startOf('hour').format(dateTimeFormat),
              { variant: 'success' })
        } else if ('PAUSED' === response.status) {
          enqueueSnackbar('Offer set to Paused state. Reason: ' + response.statusReason,
              { autoHideDuration: 10_000, variant: 'success' })
        } else {
          enqueueSnackbar('Offer Re-Launched.', { variant: 'success' })
        }

        onClose()
      })
      .catch(() => {
        enqueueSnackbar(`Can't update offer`, { variant: 'error' })
      })

  }

  return (
    <Dialog classes={classes} fullWidth maxWidth="md" open={true}>
      <DialogTitle>
        <>
          <Box mb={1}>
            Configure Offer
          </Box>
          <Stepper
            activeStep={activeStep}
            steps={['Description', 'Audience', 'Details', 'Coupon', 'Limits', 'Review']}
            onStepChange={(s) => setActiveStep(s)}
          />
        </>
      </DialogTitle>
      <DialogContent>
        {
          activeStep === 0 && !values.description.keywords &&
          <Box py={2}>
            Loading your business settings...
          </Box>
        }
        {
          activeStep === 0 && values.description.keywords &&
          <OfferDescription values={values} onClose={onClose} onComplete={val => {
            setValues({ ...values, description: val })
            validateStep(0)
          }} />
        }
        {
          activeStep === 1 &&
          <OfferAudience values={values} onClose={onClose} onComplete={val => {
            setValues({ ...values, audience: val })
            validateStep(1)
          }}
            back={() => setActiveStep(0)}
          />
        }
        {
          activeStep === 2 &&
          <OfferDetails values={values} onClose={onClose} onComplete={val => {
            setValues({ ...values, details: val })
            validateStep(2)
          }}
            back={() => setActiveStep(1)}
          />
        }
        {
          activeStep === 3 &&
          <OfferCode values={values} onClose={onClose} onComplete={val => {
            setValues({ ...values, code: val })
            validateStep(3)
          }}
            back={() => setActiveStep(2)}
          />
        }
        {
          activeStep === 4 &&
          <OfferBudget values={values} onClose={onClose} onComplete={val => {
            setValues({ ...values, budget: val })
            validateStep(4)
          }}
            back={() => setActiveStep(3)}
          />
        }
        {
          activeStep === 5 &&
          <OfferReview back={() => setActiveStep(4)} values={values} onClose={onClose} onComplete={handleSubmit} resetToStart={() => setActiveStep(0)} />
        }
      </DialogContent>
    </Dialog>
  )
}

export default OfferWizard
