import React from 'react'
import { Box, FormControl, FormHelperText, Slider, Typography } from '@material-ui/core' 
import AddressSearch from '../AddressSearch'

const LocationLocal = ({ error, updateLocation, value }) => {
  const handleLocationChange = (val) => {
    updateLocation(val)
  }

  return (
    <>
      <Box mb={2}>
        <FormControl fullWidth error={error}>
          <AddressSearch
            onSelect={(v) => {
              handleLocationChange({
              ...value,
              address: v.fullAddressResult,
              lat: v.latitude,
              lng: v.longitude,
            })}}
            label={"Your business address"}
            value={value.address}
          />
          {
            error &&
            <FormHelperText>
              Enter your business address
            </FormHelperText>
          }
        </FormControl>
      </Box>
      <Box mb={2}>
        <Typography variant="body2">
          Choose radius around your business
        </Typography>
        <Slider
          aria-valuetext={`${value.radius} miles`}
          defaultValue={value.radius}
          marks={[2, 4, 8, 10, 15, 20, 30, 40, 50, 100].map(m => ({ value: m, label: '' }))}
          onChangeCommitted={(e, v) => handleLocationChange({
            ...value,
            radius: v,
          })}
          valueLabelDisplay="auto"
          step={null}
          min={2}
          max={100}
        />
        <Typography variant="body2" color="textSecondary">
          {value.radius} miles
        </Typography>
      </Box>
    </>
  )
}

export default LocationLocal
