import React, { useCallback, useState } from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  Archive,
  Edit,
  Pause,
  PlayArrow as Play,
  Layers as Clone,
} from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import * as offerActions from './utils'
import { offerStatusEnum } from 'utils/constants'
import { ConfirmationDialog } from 'components'
import { dateTimeFormat } from 'utils/time'

const OfferCard = ({
  clicks,
  offer: details,
  editOffer,
  impressions,
  revalidateOffers,
  spent,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handlePauseOffer = useCallback(() => {
    offerActions
      .pauseOffer(details.offerId)
      .then(() => {
        enqueueSnackbar('Offer paused', { variant: 'success' })
        revalidateOffers()
      })
      .catch(() => {
        enqueueSnackbar('Cannot pause offer', { variant: 'error' })
      })
  }, [details.offerId, enqueueSnackbar, revalidateOffers])

  const handleActivateOffer = useCallback(() => {
    offerActions
      .activateOffer(details.offerId)
      .then(response => {
        if ('EXPIRED' === response.status) {
          enqueueSnackbar('Offer set to Expired state.',
              { autoHideDuration: 10_000, variant: 'success' })
        } else if ('SCHEDULED' === response.status) {
          enqueueSnackbar('Offer scheduled on ' +
              moment(moment.utc(details.startDate).toISOString()).startOf('hour').format(dateTimeFormat),
              { variant: 'success' })
        } else {
          enqueueSnackbar('Offer activated.', { variant: 'success' })
        }

        revalidateOffers()
      })
      .catch(error => {
        if (error && error.status && error.status >= 400 && error.status < 500) {
          enqueueSnackbar('Offer was not activated. Reason: ' + error.message,
              { autoHideDuration: 10_000, variant: 'error' })
          revalidateOffers()
        } else {
          enqueueSnackbar('Cannot activate offer', { variant: 'error' })
        }
      })
  }, [details.offerId, enqueueSnackbar, revalidateOffers, details.startDate])

  const handleCloneOffer = useCallback(() => {
    offerActions
      .cloneOffer(details.offerId)
      .then(() => {
        enqueueSnackbar('Offer cloned in Paused state.', { variant: 'success' })
        revalidateOffers()
      })
      .catch(() => {
        enqueueSnackbar('Cannot clone offer', { variant: 'error' })
      })
  }, [details.offerId, enqueueSnackbar, revalidateOffers])

  const handleArchiveOffer = useCallback(() => {
    offerActions
      .deleteOffer(details.offerId)
      .then(() => {
        enqueueSnackbar('Offer archived', { variant: 'success' })
        revalidateOffers()
        setShowConfirmation(false)
      })
      .catch(() => {
        enqueueSnackbar('Cannot archive offer', { variant: 'error' })
      })
  }, [details.offerId, enqueueSnackbar, revalidateOffers])

  return (
    <>
      <Card>
        <CardContent style={{ padding: '8px 16px 0' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6">{details.title}</Typography>
              <Typography variant="body2" gutterBottom>
                {details.description}
              </Typography>
              {details.statusReason &&
                ((details.statusReason.endsWith(' on ') && (
                  <Typography variant="caption" color="textSecondary">
                    {details.statusReason}{' '}
                    {moment(moment.utc(
                        details.statusReason.startsWith('Scheduled') ?
                            details.startDate : details.endDate
                        ).toDate()).format(
                      dateTimeFormat
                    )}
                  </Typography>
                )) ||
                  (details.statusReason.startsWith('Live') && (
                    <Typography variant="caption" color="textSecondary">
                      {details.statusReason}, ends on{' '}
                      {moment(moment.utc(details.endDate).toDate()).format(
                        dateTimeFormat
                      )}
                    </Typography>
                  )) || (
                    <Typography variant="caption" color="textSecondary">
                      {details.statusReason}
                    </Typography>
                  ))}
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={5}
              alignItems="center"
              justify="space-evenly"
            >
              <div style={{ textAlign: 'center' }}>
                <Typography variant="h6">{clicks}</Typography>
                <Typography variant="body2" color="textSecondary">
                  Clicks
                </Typography>
              </div>
              <div style={{ textAlign: 'center' }}>
                <Typography variant="h6">{impressions}</Typography>
                <Typography color="textSecondary" variant="body2">
                  Impressions
                </Typography>
              </div>
              <div style={{ textAlign: 'center' }}>
                <Typography variant="h6">{spent}</Typography>
                <Typography color="textSecondary" variant="body2">
                  Spent
                </Typography>
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ textAlign: 'right' }}>
          {(details.status === offerStatusEnum.active || details.status === offerStatusEnum.scheduled) && (
            <Button color="primary" size="small" onClick={handlePauseOffer}>
              <Pause fontSize="small" style={{ marginRight: 4 }} />
              Pause offer
            </Button>
          )}
          {details.status === offerStatusEnum.paused && (
            <Button color="primary" size="small" onClick={handleActivateOffer}>
              <Play fontSize="small" style={{ marginRight: 4 }} />
              Activate offer
            </Button>
          )}
          {details.status !== offerStatusEnum.archived &&
            details.status !== offerStatusEnum.expired && (
              <Button
                color="primary"
                size="small"
                onClick={() => editOffer(details.offerId)}
              >
                <Edit fontSize="small" style={{ marginRight: 4 }} />
                Edit offer
              </Button>
            )}
          {
            <Button color="primary" size="small" onClick={handleCloneOffer}>
              <Clone fontSize="small" style={{ marginRight: 4 }} />
              Clone offer
            </Button>
          }
          {details.status !== offerStatusEnum.archived && (
            <Button
              color="primary"
              size="small"
              onClick={() => setShowConfirmation(true)}
            >
              <Archive fontSize="small" style={{ marginRight: 4 }} />
              Archive offer
            </Button>
          )}
        </CardActions>
      </Card>
      {showConfirmation && (
        <ConfirmationDialog
          onCancel={() => setShowConfirmation(false)}
          onConfirm={handleArchiveOffer}
          isOpen
          body="Are you sure you want to archive this offer"
          title="Archive offer"
          successButtonLabel="Archive"
        />
      )}
    </>
  )
}

export default OfferCard
