import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import TagManager from 'react-gtm-module'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@material-ui/core/styles';
import { store } from 'store'
import AppContainer from './components/AppContainer'
import { theme } from 'utils/theme'
import { gtmID } from 'utils/config'

TagManager.initialize({ gtmId: gtmID })

const App = () => {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
              <AppContainer />
            </SnackbarProvider>
          </ThemeProvider>
        </Provider>
      </QueryParamProvider>
    </Router>
  )
}

export default App
