import { isProd } from './functions'
const STRIPE_DEV = 'pk_test_ywyFzT5BNAoAswuwbz1qvLta'
const STRIPE_PROD = 'pk_live_kYi5V4xMAkGgMEqE9MCCTNzx'
const GTM_DEV = 'GTM-TXLBMZK'
const GTM_PROD = 'GTM-WXDQNMP'

export const googleApiKey = 'AIzaSyB5qzLnw-LdfExstYLn8C-lMc9HXapWdZU'

export const stripeApiKey = isProd() ? STRIPE_PROD : STRIPE_DEV
export const gtmID = isProd() ? GTM_PROD : GTM_DEV
