import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useSWR, { mutate } from 'swr'
import api from 'utils/api'
import { Box, Button, Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import BusinessPage from '../../components/BusinessPage'
import OfferWizard from './components/OfferWizard'
import OfferCard from './components/OfferCard'

const offerTypes = {
  active: 'active',
  archived: 'archived',
  scheduled: 'scheduled',
  paused: 'paused',
  expired: 'expired',
}

const useStyles = makeStyles(() => ({
  root: {
    borderBottom: `1px solid #ddd`,
  },
}))

const OFFERS_PER_PAGE = 6

const Offers = () => {
  const classes = useStyles()
  const [currentPage, setCurrentPage] = useState(1)
  const businessId = useSelector(state => state.business.businessId)
  const [offerType, setOfferType] = useState(offerTypes.active)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleChange = (e, val) => {
    setOfferType(val)
    setCurrentPage(1)
  }

  const apiRoute = `/offer/business/${businessId}?status=${offerType.toUpperCase()}&size=${OFFERS_PER_PAGE}&page=${currentPage - 1}`

  const { data: offers, error } = useSWR(apiRoute, () => api.get(apiRoute), { shouldRetryOnError: false, revalidateOnFocus: false })

  return (
    <BusinessPage title="Offers">
      <Box px={2} pb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsModalOpen(true)}
        >
          New offer
        </Button>
      </Box>
      <Tabs
        value={offerType}
        onChange={handleChange}
        fullWidth
        classes={classes}
      >
        <Tab value={offerTypes.active} label="Active" />
        <Tab value={offerTypes.paused} label="Paused" />
        <Tab value={offerTypes.scheduled} label="Scheduled" />
        <Tab value={offerTypes.expired} label="Expired" />
        <Tab value={offerTypes.archived} label="Archived" />
      </Tabs>
      <Box p={2}>
        <Grid container spacing={2}>
          {
            !offers && !error &&
            <Grid item xs={12} md={10}>
              <Typography>
                Loading your offers
              </Typography>
            </Grid>
          }
          {offers && offers.content && offers.content.map((offer, i) => {
            return (
            <Grid item xs={12} md={10} key={offer.offer.id}>
              <OfferCard {...offer} revalidateOffers={() => mutate(apiRoute)} editOffer={n => setIsModalOpen(n)} />
            </Grid>
          )})}
        </Grid>
        {
          offers && offers.totalPages > 1 &&
          <Box display="flex" justifyContent="center" pt={4}>
            <Pagination page={currentPage} count={offers.totalPages} onChange={(e, newPage) => setCurrentPage(newPage)} color="primary" />
          </Box>
        }
      </Box>
      {
        isModalOpen &&
        <OfferWizard
          offer={typeof isModalOpen === 'number' ? offers.content.find(o => o.offer.offerId === isModalOpen) : undefined}
          onClose={() => {
            setIsModalOpen(false)
            mutate(apiRoute)
          }}
        />
      }
    </BusinessPage>
  )
}

export default Offers
