import React, { useState } from 'react'
import { Box, Dialog, Tab, Tabs, useMediaQuery, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import * as uiActions from 'modules/UI/actions'
import Login from './components/Login'
import Register from './components/Register'
import { AuthPopupEnum } from 'utils/constants'

const AuthPopup = () => {
  const showAuthPopup = useSelector(state => state.ui.showAuthPopup)
  const [type, setType] = useState(showAuthPopup || AuthPopupEnum.login)
  const dispatch = useDispatch()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog fullWidth maxWidth="xs" open onClose={() => dispatch(uiActions.hideLogin())}>
      <Box p={matches ? 2 : 4} pt={matches ? 1 : 2}>
        <Box mb={4}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={type}
            fullWidth
            style={{ borderBottom: `1px solid #eee` }}
            onChange={(e, val) => setType(val)}
          >
            <Tab label="Login" value={AuthPopupEnum.login} style={{ width: '50%' }} />
            <Tab label="Register" value={AuthPopupEnum.register} style={{ width: '50%' }} />
          </Tabs>
        </Box>
        {
          type === AuthPopupEnum.login &&
          <Login />
        }
        {
          type === AuthPopupEnum.register &&
          <Register />
        }
      </Box>
    </Dialog>
  )
}

export default AuthPopup
