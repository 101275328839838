import React, { useRef, useState } from 'react'
import { useSnackbar } from 'notistack'
import { CircularProgress, FormControl, FormHelperText, IconButton, TextField } from '@material-ui/core'
import { Folder } from '@material-ui/icons'
import { Controller } from 'react-hook-form'
import Cookies from 'js-cookie'
import { parseResponse } from 'utils/api'
import { isProd } from 'utils/functions'

const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']

const ImageUpload = ({
  control,
  error,
  name = 'offerImage',
  placeholder = 'Cover image',
  uploadUrl,
}) => {
  const inputRef = useRef(null)
  const [isUploading, setIsUploading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleImageSelect = (e, callback) => {
    const files = e.target.files
    if (!SUPPORTED_IMAGE_FORMATS.includes(files[0].type)) {
      enqueueSnackbar('Please choose only .jpg or .png images', { variant: 'error' })
      return
    }
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.result) {
        uploadLogo(files[0], callback)
      }
    }
    reader.readAsDataURL(files[0])
  }

  const uploadLogo = (img, callback) => {
    const formData = new FormData()
    formData.append('file', img)
    setIsUploading(true)
    fetch(uploadUrl, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${Cookies.get('access_token') || ''}`,
      },
      mode: 'cors',
      withCredentials: true,
      credentials: 'include',
    })
    .then(res => parseResponse(res))
    .then((response) => {
      enqueueSnackbar('Image uploaded', { variant: 'success'})
      const host = isProd() ? window.location.origin : 'https://staging.hiddenunderground.com'
      const url = `${host}${response.result}`
      callback(url)
    })
    .catch(() => {
      enqueueSnackbar('Cannot upload image, please try again', { variant: 'error'})
    })
    .finally(() => {
      setIsUploading(false)
    })
  }
  const handleClick = () => {
    if (!inputRef || !inputRef.current) {
      return
    }
    inputRef.current.click()
  }

  return (
    <>
      <FormControl fullWidth variant="outlined" margin="dense">
        <Controller
          control={control}
          name={name}
          render={({ value, onChange }) => (
            <>
              <TextField
                label={value == null ? placeholder : ""}
                inputProps={{ disabled: true }}
                InputProps={{
                  endAdornment: isUploading ? 
                    <CircularProgress size={20} />
                    :
                    <IconButton onClick={handleClick} size="small"><Folder /></IconButton>
                }}
                variant="outlined"
                margin="dense"
                value={value}
              />
              <input
                accept=".png,.jpg,.jpeg"
                onChange={(e) => handleImageSelect(e, onChange)}
                ref={inputRef}
                type="file"
                style={{
                  visibility: 'hidden',
                  width: 0,
                  position: 'absolute',
                }}
              />
            </>
          )}
        />
        {
          error &&
          <FormHelperText error>
            {error.message}
          </FormHelperText>
        }
      </FormControl>
    </>
  )
}

export default ImageUpload
