import React, {useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers'
import * as yup from 'yup'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core'
import Barcode from 'components/offers/ClaimPopup/components/Barcode'
import Qrcode from 'components/offers/ClaimPopup/components/Qrcode'

const codeType = {
  promoCode: 0,
  barcode: 1,
  qrCode: 2,
}

const schema = yup.object().shape({
  promoCode: yup.string().when('promoCodeDefined', { is: true, then: yup.string().required('Enter alphanumeric promo code')}),
  barcode: yup
    .string()
    .when('barcodeDefined',
      {
        is: true,
        then: yup
          .string()
          .required('Enter barcode value')
          .min(1, 'Enter barcode value')
          .test(
            'is-valid-barcode',
            'Code is not a valid barcode format',
            async (val) => (await fetch(`/api/barcodes/barcode?code=${val}`)).ok
          )
      }
    ),
  qrCode: yup
    .string()
    .when('qrCodeDefined', { is: true, then: yup.string().required('Enter QR code value')}),
})

const mapValues = (values) => {
  return {
    promoCode: values.promoCode,
    promoCodeDefined: !!values.promoCode,
    barcode: values.barcode,
    barcodeDefined: !!values.barcode,
    qrCode: values.qrCode,
    qrCodeDefined: !!values.qrCode,
  }
}

const OfferCode = ({ back, onClose, onComplete, values }) => {
  const { control, register, handleSubmit, errors, setValue, watch } = useForm({
    defaultValues: values.code ? mapValues(values.code) : {},
    resolver: yupResolver(schema),
    mode: 'onChange',
  })
  const currentValues = watch()

  const getDefinedType = (deselected) => {
    if (currentValues.barcodeDefined && codeType.barcode !== deselected) {
      return codeType.barcode
    } else if (currentValues.qrCodeDefined && codeType.qrCode !== deselected) {
      return codeType.qrCode
    } else if (currentValues.promoCodeDefined && codeType.promoCode !== deselected) {
      return codeType.promoCode
    }

    return null;
  }

  const hasDefinedCode = (typeToCheck) => {
    let result = false

    if (codeType.barcode === typeToCheck) {
      result = (currentValues && !!currentValues.barcode) || (values && values.code && !!values.code.barcode)
    } else if (codeType.qrCode === typeToCheck) {
      result = (currentValues && !!currentValues.qrCode) || (values && values.code && !!values.code.qrCode)
    } else if (codeType.promoCode === typeToCheck) {
      result = (currentValues && !!currentValues.promoCode) || (values && values.code && !!values.code.promoCode)
    }

    return result
  }

  const noneSelected = () => {
    return !currentValues.qrCodeDefined && !currentValues.promoCodeDefined && !currentValues.barcodeDefined;
  }

  const [currentType, setCurrentType] = useState(() => {
    return getDefinedType();
  })

  const handleFormSubmit = (data) => {
    const codes = {
      promoCode: data.promoCode,
      barcode: data.barcode,
      qrCode: data.qrCode,
    }
    if (!codes.promoCode && !codes.barcode && !codes.qrCode) {
      return
    }
    onComplete(codes)
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Box mb={4}>
        <Typography variant="h6" component="h2">
          Set your coupon code
        </Typography>
      </Box>
      <Grid container spacing={3} sm={12}>
        <Grid item xs={12} md={6}>
          <Box>
            <Controller
              name="promoCodeDefined"
              control={control}
              render={({ value, onChange}) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value}
                      onChange={(e) => {
                        const currentValue = e.target.checked
                        onChange(currentValue)

                        if (!currentValue) {
                          setValue('promoCode', '')
                          setCurrentType(getDefinedType(codeType.promoCode))
                        } else {
                          if (noneSelected()) {
                            setCurrentType(codeType.promoCode)
                          }
                        }
                      }}
                    />
                  }
                  label="I have alphanumerical promo code"
                />
              )}
            />
            {
              currentValues.promoCodeDefined &&
              <FormControl fullWidth variant="outlined">
                <TextField
                  fullWidth
                  label="Alphanumeric offer code"
                  defaultValue=""
                  name="promoCode"
                  inputRef={register}
                  variant="outlined"
                  margin="dense"
                  multiline
                  onInput={
                    (input) => {
                      if (input.target.value) {
                        setCurrentType(codeType.promoCode)
                      }
                    }
                  }
                />
                <FormHelperText error>
                  <Box display="flex" justifyContent="space-between">
                    <span>
                      {errors.promoCode ? errors.promoCode.message : ''}
                    </span>
                    <Typography color="textSecondary" variant="caption">
                      {currentValues.promoCode ? currentValues.promoCode.length : 0} / 25
                    </Typography>
                  </Box>
                </FormHelperText>
              </FormControl>
            }
          </Box>
          <Box>
            <Controller
              name="barcodeDefined"
              control={control}
              render={({ value, onChange}) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value}
                      disabled={currentValues.qrCodeDefined}
                      onChange={(e) => {
                        const currentValue = e.target.checked
                        onChange(currentValue)
                        if (!currentValue) {
                          setValue('barcode', '')
                          setCurrentType(getDefinedType(codeType.barcode))
                        } else {
                          if (noneSelected()) {
                            setCurrentType(codeType.barcode)
                          }
                        }
                      }}
                    />
                  }
                  label="I have barcode"
                />
              )}
            />
            {
              currentValues.barcodeDefined &&
              <FormControl fullWidth variant="outlined">
                <TextField
                  fullWidth
                  label="Barcode"
                  defaultValue=""
                  name="barcode"
                  inputRef={register}
                  variant="outlined"
                  margin="dense"
                  multiline
                  onInput={
                    (input) => {
                      if (input.target.value) {
                        setCurrentType(codeType.barcode)
                      }
                    }
                  }
                />
                <FormHelperText>
                  Barcodes are generated in the CODE-128 standard,  enclosing the code with * (e.g. *MY BARCODE*) will render the code in CODE-39 standard.
                </FormHelperText>
                {
                  errors.barcode &&
                  <FormHelperText error>
                    {errors.barcode.message}
                  </FormHelperText>
                }
              </FormControl>
            }
          </Box>
          <Controller
            name="qrCodeDefined"
            control={control}
            render={({ value, onChange}) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    disabled={currentValues.barcodeDefined}
                    onChange={(e) => {
                      const currentValue = e.target.checked
                      onChange(currentValue)
                      if (!currentValue) {
                        setValue('qrCode', '')
                        setCurrentType(getDefinedType(codeType.qrCode))
                      } else {
                        if (noneSelected()) {
                          setCurrentType(codeType.qrCode)
                        }
                      }
                    }}
                  />
                }
                label="I have QR code"
              />
            )}
          />
          {
            currentValues.qrCodeDefined &&
            <FormControl fullWidth variant="outlined">
              <TextField
                fullWidth
                label="QR code"
                defaultValue=""
                name="qrCode"
                inputRef={register}
                variant="outlined"
                margin="dense"
                multiline
                onInput={
                  (input) => {
                    if (input.target.value) {
                      setCurrentType(codeType.qrCode)
                    }
                  }
                }
              />
              {
                errors.qrCode &&
                <FormHelperText error>
                  {errors.qrCode.message}
                </FormHelperText>
              }
            </FormControl>
          }
        </Grid>
        {/* change to false to remove barcode preview */}
        {
          true &&
          <Grid item xs={12} md={6}>
            <Tabs centered onChange={(e, val) => setCurrentType(val)} value={currentType}>
              {currentValues.promoCodeDefined && <Tab label="Promo code" value={codeType.promoCode} />}
              {currentValues.barcodeDefined && <Tab label="Barcode" value={codeType.barcode} />}
              {currentValues.qrCodeDefined && <Tab label="QR Code" value={codeType.qrCode} />}
            </Tabs>
            {currentType === codeType.qrCode && (hasDefinedCode(codeType.qrCode) ?
              <Qrcode code={currentValues.qrCode || values.code.qrCode} /> : '')}
            {currentType === codeType.barcode && (hasDefinedCode(codeType.barcode) && (errors && ! errors.barcode) ?
              <Barcode code={currentValues.barcode || values.code.barcode} /> : '')}
            {currentType === codeType.promoCode && (
              <Box
                borderRadius={4}
                p={4}
                mb={1}
                style={{ border: '1px solid #ddd' }}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="h3" color="primary" style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word', textAlign: 'center' }}>
                  {hasDefinedCode(codeType.promoCode) ? currentValues.promoCode || values.code.promoCode : ''}
                </Typography>
              </Box>
            )}
          </Grid>
        }
      </Grid>
      <Box mt={2} mb={1} display="flex" justifyContent="space-between">
        <Box>
          <Button onClick={onClose}>
            Cancel
          </Button>
        </Box>
        <Box>
          <Button
            color="primary"
            onClick={back}
          >
            Back
          </Button>
          <Button
            color="primary"
            onClick={handleSubmit}
            variant="contained"
            type="submit"
          >
            Next
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default OfferCode