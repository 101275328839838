import React from 'react'
import { FormControl, TextField, FormHelperText } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { STATE_FULL_NAME } from 'utils/constants'

const LocationState = ({ error, updateLocation, value }) => {
  const states = Object.keys(STATE_FULL_NAME).map(s => ({
    label: STATE_FULL_NAME[s],
    value: s,
  }))
  return (
    <>
      <FormControl variant="outlined" error={true} fullWidth>
        <Autocomplete
          onChange={(e, val) => updateLocation(val.map(v => v.value))}
          getOptionLabel={o => o.label}
          size="small"
          multiple
          options={states}
          value={states.filter(s => {
            return value.indexOf(s.value) !== -1
          })}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              label="States"
              InputLabelProps={{
                color: 'primary',
              }}
              variant="outlined"
            />
          )}
        />
        {
          error &&
          <FormHelperText>
            Select at least 1 state from the list
          </FormHelperText>
        }
      </FormControl>
    </>
  )
}

export default LocationState
