import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Box, Container, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Page } from 'components'
import Helmet from "react-helmet";

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <Page>
      <Helmet>
        <title>Help - HiddenUnderground</title>
      </Helmet>
      <Container maxWidth="md">
        <Box my={10}>
          <Typography variant="h4" component="h1">
            Frequently asked questions
          </Typography>
          <Box my={6}>
            <Typography variant="h5" component="h2" gutterBottom>
              Consumers
            </Typography>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  What happened to the offer I found earlier?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  When a business provides an offer through HiddenUnderGround, they have the option to run that offer for a set amount of time, or until the offer has been clicked on a set number of times. We try to provide consumers with a notice if the offer will expire in 2 days, or is reaching the maximum number of clicks.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  Will an offer that has expired, or is no longer searchable, return?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  It is up to the retailer, or business providing the offer to bring it back.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  Do I need to create an account to see the offer code?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  No, You do not need to create an account.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  Why should I create a personal account?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  You do not need to create a personal account to use the site. Personal accounts are provided as a convenient way of being able to save offers you like, email offers, and more accurately find local offers based upon your location.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  You say offers should work 99% of the time, but my offer code didn’t work. Why?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  Certain manufactures will not allow any offers, discounts or special pricing to be applied to their products. It is important to check the offer details to make sure the item you want to buy is included in the offer.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  I clicked on an offer, and wrote down my code. A few days later, I tried to use my code, but it didn’t work. Why?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  When you found your offer, the offer code was valid. Offer codes have expiration dates, and it is possible that the code expired before you were able to use it. If you have created a personal account with HiddenUnderGround, and liked the offer, you should be able to see within your account if the offer has expired.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  I read the offer details. The item I want to buy is not excluded, but my offer code still did not work. What should I do?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  If you believe that an offer should have worked and didn’t, send us an email <a href="mailto:support@invensta.com">here</a>, and we will forward your email to the business providing the offer. If we receive multiple complaints against an offer, we will remove the offer.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  I found a better offer on HiddenUnderGround. Can I cancel my order?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  HiddenUnderGround does not sell the product(s) associated with the offer. If you wish to cancel an order, you will need to contact the company from which the purchase was made.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  The second time I tried to use the offer code it didn’t work, why?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  Some manufacturers and retailers only provide a one time use offer. It is important to check the offer details for any limitations.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  Where can I purchase the offer?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  HiddenUnderGround does not sell any products or offers through the site. Once you have clicked on the offer code, you need to take it to the website providing the offer, or take it to the brick and mortar location to take advantage of the offer.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Box>
          <Box my={6}>
            <Typography variant="h5" component="h2" gutterBottom>
              Business
            </Typography>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  Should I provide a generic offer code, or one specific to HiddenUnderGround?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  When creating an offer code, it is recommended that you create a code that is associated with HiddenUnderGround. This will allow you to track and measure the effectiveness of HiddenUnderGround and the offer.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  Can I use a pre-generated offer code?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  You can use any valid pre-generated offer code, bar code, or qr code. We recommend you use your analytics to measure the effectiveness of HiddenUnderGround and the offer when using a generic code.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  How long should I run my offer on HiddenUnderGround?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  You are in control of the offer duration, and should set the length based upon your campaign.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  My offer is a huge success, how do I extend the offer?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  Within your account, click on current offers. Find the offer you want to extend, and click on “edit”: Adjust the “end date” and “clicks limit” and click “Save”.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  Can I pre-create offers before my campaign goes live?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  Yes, you have the ability to set an offer start date and end date.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  Can I stop or pause an offer before the end date, or maximum clicks is reached?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  Yes, you can pause an offer whenever you like.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  How many offers can I run at a time?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  You can run as many offers as you like.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  My offers are performing really well, but it looks like they have all paused, what happened?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  Check the payments section of your dashboard for any notifications. If there are no notification and the offers are not paused, contact us here.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  Are my sales processed through your site?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  No, HiddenUnderGround only displays your offers based upon search results.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  Is HiddenUnderGround an affiliate site?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  No, HiddenUnderGround will not create an affiliate agreement.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  Does HiddenUnderGround take a percentage of referred sales?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  No, HiddenUnderGround will not take a percentage of your sales generated from the HiddenUnderGround site.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  How does HiddenUnderGround charge?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="body2" color="textSecondary" paragraph>
                  HiddenUnderGround charges a CPC (cost per click) when a visitor clicks on your offer.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Box>
          <Typography paragraph>
            For additional questions, please email support on <a href="mailto:HelpPlease@hiddenunderground.com">HelpPlease@hiddenunderground.com</a> or visit the <NavLink to="/feedback">feedback page</NavLink>.
          </Typography>
        </Box>
      </Container>
    </Page>
  )
}
export default FAQ
