import React, { useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers'
import * as yup from "yup";
import * as uiActions from 'modules/UI/actions'
import { fetchBusinesses } from 'modules/User/actions'
import * as businessActions from 'modules/Business/actions'
import api from "utils/api";
import CategorySelector from './components/CategorySelector'
import {STATE_FULL_NAME} from 'utils/constants'
import ImageUpload from 'modules/Business/modules/Offers/components/OfferDetails/components/ImageUpload'

const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/

yup.addMethod(yup.mixed, 'defined', function () {
  return this.test('defined', "{path} is required", value => value !== undefined)
})

const schema = yup.object().shape({
  companyName: yup.string().required('Name is required'),
  street: yup.string().required('Street is required'),
  city: yup.string().required('City is required'),
  zipCode: yup.number().typeError('Zipcode must be a number').required('Zipcode is required'),
  state: yup.mixed().defined(),
  siteUrl: yup.string().matches(urlPattern, 'Url is not valid').max(240, 'Too many characters').required('Site url is required'),
  logoUrl: yup.string().required('Logo is required'),
  categories: yup.mixed().required('Pick a category'),
})

const BusinessForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isEdit = useSelector(s => s.ui.showBusinessForm)
  const business = useSelector(s => s.business)

  const biz = business.businessId ? {
    categories: business.categories,
    companyName: business.companyInfo.companyName,
    street: business.companyInfo.street,
    zipCode: business.companyInfo.zipCode,
    city: business.companyInfo.city,
    state: business.companyInfo.state !== null ?
        business.companyInfo.state.toUpperCase() : business.companyInfo.state,
    siteUrl: business.companyInfo.siteUrl,
    logoUrl: business.companyInfo.logoUrl,
  } : {}

  const { register, handleSubmit, errors, control, watch } = useForm({
    defaultValues: isEdit === 1 ? biz : {},
    resolver: yupResolver(schema)
  })

  const currentState = watch('state')

  const handleFormSubmit = ({ categories, ...companyInfo }) => {
    const businessId = isEdit === 1 ? business.businessId : null;
    const payload = {

      businessId: businessId,
      companyInfo: {
        ...companyInfo,
        businessId: businessId,
      },
      categories: categories.map(c => c.categoryId),
    }
    postCompany(payload)
  }

  const postCompany = async (payload) => {
    try {
      const businessId = await api.post('/business', payload)
      dispatch(uiActions.hideBusinessForm())
      if (!businessId.error) {
        if (isEdit === 1) {
          dispatch(businessActions.fetchBusiness(business.businessId))
          api.get('/user/businesses/')
            .then(res => {
              dispatch(fetchBusinesses(res.content))
            })
        } else {
          history.push(`/business/${businessId}`)
          api.get('/user/businesses/')
            .then(res => {
              dispatch(fetchBusinesses(res.content))
            })
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleClose = useCallback(
    () => {
      dispatch(uiActions.hideBusinessForm())
    }, [dispatch]
  )

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={handleClose}>
      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <DialogTitle>
          {isEdit === 1 ? 'Edit business' : 'Create new business'}
        </DialogTitle>
        <DialogContent>
          <Box my={2}>
            <FormControl
              fullWidth
              variant="outlined"
            >
              <TextField
                autoFocus
                fullWidth
                label="Business name"
                margin="dense"
                name="companyName"
                inputRef={register}
                variant="outlined"
              />
              {
                errors.companyName &&
                <FormHelperText error>
                  {errors.companyName.message}
                </FormHelperText>
              }
            </FormControl>
          </Box>
          <Box my={2}>
            <FormControl
              fullWidth
              variant="outlined"
            >
              <TextField
                fullWidth
                label="Street"
                margin="dense"
                name="street"
                inputRef={register}
                variant="outlined"
              />
              {
                errors.street &&
                <FormHelperText error>
                  {errors.street.message}
                </FormHelperText>
              }
            </FormControl>

          </Box>
          <Box my={2}>
            <Grid container spacing={1}>
              <Grid item sm={5}>
                <FormControl
                  fullWidth
                  variant="outlined"
                >
                  <TextField
                    fullWidth
                    label="City"
                    margin="dense"
                    name="city"
                    inputRef={register}
                    variant="outlined"
                  />
                  {
                    errors.city &&
                    <FormHelperText error>
                      {errors.city.message}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item sm={3}>
                <FormControl
                  fullWidth
                  variant="outlined"
                >
                  <TextField
                    fullWidth
                    label="Zip code"
                    margin="dense"
                    name="zipCode"
                    inputRef={register}
                    variant="outlined"
                    type="number"
                  />
                  {
                    errors.zipCode &&
                    <FormHelperText error>
                      {errors.zipCode.message}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth variant="outlined" error={errors.state} margin="dense">
                  <InputLabel id="company-info-state" shrink={currentState === null || typeof currentState === 'string'}>State</InputLabel>
                  <Controller
                    name="state"
                    control={control}
                    as={
                      <Select
                        id="company-info-state"
                        displayEmpty
                        fullWidth
                        margin="dense"
                        disableUnderline
                        variant="outlined"
                        labelWidth={40}
                      >
                        <MenuItem value={null}>Outside US</MenuItem>
                        {Object.entries(STATE_FULL_NAME).map(([abbr, name]) => (
                          <MenuItem value={abbr}>{name}</MenuItem>
                        ))}
                      </Select>
                    }
                  />
                  {errors.state && (
                    <FormHelperText>{errors.state.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box my={2}>
            <FormControl
              fullWidth
              variant="outlined"
            >
              <TextField
                defaultValue=""
                fullWidth
                label="Site url"
                margin="dense"
                name="siteUrl"
                inputRef={register}
                variant="outlined"
              />
              {
                errors.siteUrl &&
                <FormHelperText error>
                  {errors.siteUrl.message}
                </FormHelperText>
              }
            </FormControl>
          </Box>
          <Box my={2}>
            <ImageUpload
              control={control}
              error={errors.logoUrl}
              placeholder="Business logo"
              name="logoUrl"
              uploadUrl={`/api/business/saveImage`}
            />
          </Box>
          <Box my={2}>
            <FormControl
              fullWidth
              variant="outlined"
            >
              <CategorySelector control={control} />
              {
                errors.categories &&
                <FormHelperText error>
                  {errors.categories.message}
                </FormHelperText>
              }
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            dispatch(uiActions.hideBusinessForm())
          }}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {
              isEdit === 1 ?
              'Update' : 'Create'
            }
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BusinessForm;
